@import "../../App.scss";

.create_coupon-wrapper {
    width: 40rem;
}

////////////////////////
.create_coupon_main {
    width: 100%;
    height: 100%;
}

.create-coupon-count-container {
    width: fit-content;
    height: 110px;
    position: fixed;
    right: 0;
    text-align: center;
    background-color: white;
    font-family: 'GothamMedium';
    margin-right: 15%;
}

.create-coupon-count-container .create-coupon-count {
    width: fit-content;
    height: 60px;
    margin: 0 auto;
    border-radius: 50px;
    background-color: #51B7F3;
    color: white;
    font-size: 25px;
    padding: 10px;
}

.create_coupon_inside {
    position: relative;
    width: 650px;
    height: 750px;
    background-color: white;
}

.create_coupon_close {
    position: absolute;
    right: 3%;
    top: 3%;
}

.create-coupon-form {
    width: 100%;
    //border: rgba(0,0,0,0.3) solid 1px;
}


.create-coupon-input-container {
    position: relative;
}

.create-coupon-input-label {
    font-size: 15px;
    color: black;
    font-family: 'GothamMedium';
}

.create-coupon-input-label-description {
    font-size: 13px;
    color: #4eb6b2;
    font-family: 'GothamBook';
}

.create-coupon-input-container input:not([type='checkbox']) {
    width: 20%;
    color: #626262;
    font-size: 15px;
    padding-left: 5px;
}

.create_coupon_button {
    color: #51B7F3;
    background-color: white;
    width: 250px;
    height: 50px;
    border: #51B7F3 solid 2px;
    border-radius: 5px;
    font-family: 'GothamMedium';
    font-size: 15px;
}