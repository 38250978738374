@import "../../App.scss";

.cover-categories_main {
    background-color: rgba(0,0,0,0.03);
}

.cover-categories_header {
    background-color: #ffffff;
    height: 5vh;
}

.cover-categories_back-btn {
    width: 15%;
    padding-left: 40px;
}

.cover-categories_content {
    width: 100%;
    height: 100%;
}

.cover-categories_center {
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
}

.cover-categories_list-detail {
    width: 70%;
    max-width: 700px;
    // background-color: white;
    // box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.03);
}

.border{
    border-bottom: 1px solid rgba(0,0,0,0.08);
    width: 100%;
}

.collections_customer-products {
    width: 100%;
}

.collections_customer-products-inside {
    width: 95%;
}

.collections_container {
    background-color: rgba(0,0,0,0.03);
    height: auto;
    width: 100%;
    padding: 50px 0px;
}

.header_blue {
    color-adjust: #51B7F3;
}