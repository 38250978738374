@import "../../App.scss";

.file-name_container {
    width: 100%;
    height: 100%;
}

.file-container {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
}

.file_list-container {
    height: 100%;
    overflow-y: scroll;
    width: 60%;
    max-width: 800px;
    min-height: 90px;
    max-height: 250px;
}

.file-name_container-inside {
    position: relative;
    width: 50%;
    max-width: 700px;
    height: 45%;
    min-height: 250px;
    max-height: 450px;
    background-color: white;
    border-radius: 15px;
}

.file-name_close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.file-name_count-characters {
    width: 60%;
}

@media(max-width: 1100px) {
    .edit-name_container-inside {
        position: relative;
        width: 80%;
        height: 35%;
        background-color: white;
    }
    
}

.file-name_buttons-container {
    width: 60%;
}

.file-name_input-container {
    width: 60%;
    border-bottom: rgba(0,0,0,1) 1px solid;
}

.file-name_ok-button {
    width: 45%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.file-name_cancel-button {
    width: 45%;
    height: 40px;
    color: #51B7F3;
    border: #51B7F3 2px solid;
    background-color:  white;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.image-container {
    border: #BDBDBD 2px dashed;
    width: 60%;
    max-width: 800px;
    height: 350px;
}

.file-item_main {
    width: 98%;
}

.file-single-item_main {
    width: 60%;
}

.file-item_show-img {
    height: 16px;
    width: 16px;
    object-fit: cover;   
}