.predesignsoft21_main {
    width: 43.1cm;
    height: 21.65cm;
    font-size: 12px;
    -webkit-print-color-adjust: exact;
}

.predesignsoft21_back {
    width: 21.3cm;
    height: 21.6cm;
    float: left;
    position:relative;
}

.predesignsoft21_pixylogo-back {
    position: absolute;
    bottom: 1.2cm;
    left: 0; 
    right: 0; 
    margin-left: auto;
    margin-right: auto;
    width: 2.7cm;
    height: 1.65cm;
    background-color: white;
    border-radius: 4px;
} 


.predesignsoft21_codebar-back {
    width: 2.7cm;
    height: 0.4cm;
    background-color: white;
    position: absolute;
    bottom: 1.9cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}

.predesignsoft21_barcode-img {
    width: 100%;
    height: 0.4cm;
    object-fit: cover;
}

.predesignsoft21_order-number {
    width: 100%;
    position:absolute;
    bottom: 1.3cm;
    height: 6mm;
    padding-top: 0.1cm;
    margin-left: auto; 
    margin-right: auto; 
}

.predesignsoft21_spine {
    width: 0.5cm;
    height: 21.6cm;
    float:left;
}

.predesignsoft21_front {
    width: 21.3cm;
    height: 21.6cm;
    float:left;
}