.predesignhard21_main {
    font-size: 12px;
    margin: 0;
    width: 46.75cm;
    height: 25.05cm;
    -webkit-print-color-adjust: exact;
}

.predesignhard21_line-one {
    margin-left: 2cm;
    height: 0.8cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-two {
    margin-left: 21.7cm;
    height: 0.8cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-three {
    margin-left: 23cm;
    height: 0.8cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-three-red {
    margin-left: 23.35cm;
    height: 0.8cm;
    width: 6px;
    background: red;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-four {
    margin-left: 23.7cm;
    height: 0.8cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-five {
    margin-left: 25cm;
    height: 0.8cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-six {
    margin-left: 44.7cm;
    height: 0.8cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
        
}

.predesignhard21_line-seven {
    width: 0.8cm;
    margin-top: 2cm;
    height: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-eight {
    width: 0.8cm;
    margin-top: 2cm;
    margin-left: 45.9cm;
    height: 6px;
    background: #000;
    position: absolute;
    border-left: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-nine {
    width: 0.8cm;
    margin-top: 23cm;
    height: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-ten {
    width: 0.8cm;
    margin-top: 23cm;
    margin-left: 45.9cm;
    height: 6px;
    background: #000;
    position: absolute;
    border-left: 2px solid white;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.predesignhard21_line-eleven {
    margin-left: 2cm;
    height: 0.8cm;
    margin-top: 24.15cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.predesignhard21_line-twelve {
    margin-left: 21.7cm;
    height: 0.8cm;
    margin-top: 24.15cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.predesignhard21_line-thirteen {
    margin-left: 23cm;
    height: 0.8cm;
    margin-top: 24.15cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.predesignhard21_line-thirteen-red {
    margin-left: 23.35cm;
    height: 0.8cm;
    margin-top: 24.15cm;
    width: 6px;
    background: red;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.predesignhard21_line-fourteen {
    margin-left: 23.7cm;
    height: 0.8cm;
    margin-top: 24.15cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.predesignhard21_line-fifteen {
    margin-left: 25cm;
    height: 0.8cm;
    margin-top: 24.15cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.predesignhard21_line-sixteen {
    margin-left: 44.7cm;
    height: 0.8cm;
    margin-top: 24.15cm;
    width: 6px;
    background: #000;
    position: absolute;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-top: 2px solid white;
}


.predesignhard21_back {
    width: 23cm;
    height: 25cm;
    float: left;
    position:relative;
}


.predesignhard21_pixylogo-back {
    width: 2.7cm;
    height: 1.6cm;
    position: absolute;
    bottom: 3.3cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background: white;
    border-radius: 4px;
}

.predesignhard21_logo-back {
    width: 2.7cm;
    height: 0.8cm;
    object-fit: contain;
}

.predesignhard21_container-codebar-back {
    width: 2.7cm;
    height: 0.3cm;
    position: absolute;
    bottom: 3.9cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100px;
}

.predesignhard21_codebar-back {
    width: 100%;
    height: 0.4cm;
    object-fit: cover;
}


.predesignhard21_order-number {
    width: 100%;
    position:absolute;
    bottom: 3cm;
    height: 0.8cm;
    padding-top: 0.1cm;
}

.predesignhard21_spine {
    width: 0.7cm;
    height: 25cm;
    float:left;
}

.predesignhard21_front {
    width: 23cm;
    height: 25cm;
    float:left;
}