
.softcover_main {
    font-family: Helvetica, sans-serif;
    width: 26.22cm;
    margin: 0;
    font-size: 12px;
    -webkit-print-color-adjust: exact;
}

.softcover_indicators-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 26.22cm;
    height: 40px;
}

.softcover_indicator {
    height: 40px;
    width: 3px;
}

.softcover_page {
    position: relative;
    width: 26.22cm;
    height: 26.67cm;
}

.softcover_ordernum {
    position: absolute;
    left: 30px;
    top: 23.3cm;
    -webkit-transform: rotate(-90deg);
    color: #c3c3c3;
    font-size: 13px;
}

.softcover_header, .softcover_footer{
    height: 4.065cm;
    width: 18.54cm;
    overflow: hidden;
    // background-color: lightgray;
}

.softcover_date {
    padding-top: 3cm;
    color: #a0a0a0;
    font-size: 15px;
}

.softcover_comment {
    padding-top: .5cm;
    text-align: center;
    font-size: 15px;
}