
.status-item_img-container {
    height: 60px; 
    width: 60px; 
    border-radius: 15px;
    position: relative;
}

.status-item_reprint-sm {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 12px;
}

.status-item_img {
    height: 30px;
}

@media(max-height: 760px) {
    .status-item_img-container {
        height: 45px; 
        width: 45px; 
        border-radius: 15px;
        position: relative;
    }
    
    .status-item_reprint-sm {
        position: absolute;
        top: 3px;
        right: 3px;
    }

    .status-item_img {
        height: 20px;
    }
    
} 