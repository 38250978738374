
.album-template_main {
    width: 23.998cm;
    -webkit-print-color-adjust: exact;
}

.album-template_page {
    position: relative;
    width: 23.48cm;
    height: 23.932cm; 
}

.album-template_ordernum {
    position: absolute;
    left: .9584cm;
    top: 21.6cm;
    -webkit-transform: rotate(-90deg);
    color: #c3c3c3;
    font-size: 16px;
}

.album-template_indicators-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 23.48cm;
    height: 1.25cm;
}

.album-template_header, .album-template_footer {
    height: 2.9915cm;
    width: 17.949cm;
    overflow: hidden;
}

.album-template_indicator {
    height: 1.15cm;
    width: 3px;
}

.album-template_date {
    color: #a0a0a0;
    padding-top: 1.7cm;
    font-size: 16px;
}

.album-template_comment {
    padding-top: .7478cm;
    text-align: center;
    font-size: 16px;
}