.login_main {
    height: 100vh;
}

.login_left-container {
    background-image: url('../../images/login-img.png');
    height: 100%;
    background-size: cover;
}

.login_right-container  {
    height: 100%;
}

.login_input-container {
    width: 80%;
    max-width: 700px;
}

.login_right-container img {
    position: absolute;
    right: 5%;
    top: 4%;
}

.login_input {
    width: 100%;
    height: 50px;
    padding-left: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 8px;
}
