.user-detail_main {
    background-color: rgba(0,0,0,0.03);
}

.user-detail_container-inside_border {
    width: 100%;
    background-color: white;
    border: hidden;
    border-radius: 10px;
}

.user-detail_container {
    width: 100%;
    background-color: white;
    border: hidden;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.03);
}

.user-detail_container-inside {
    width: 35%;
    min-width: 500px;
    border: hidden;
}

.user-detail_item-gray {
    background-color: #F3F3F3;
    border: hidden;
    border-radius: 10px;
}

.user-address_title-gray {
    width: 350px;
    background-color: #F3F3F3;
    border: hidden;
    border-radius: 10px;
}

.user-detail_item-column {
  width: calc(100vw / 8);
  height: 100px;
}

.avatar {
  height: 120px;
  width: 120px;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: 100% auto;
  border-color: coral;
  object-fit: cover;
}