
.magnet_main-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    font-family: Helvetica, sans-serif;
    margin: 0;
    -webkit-print-color-adjust: exact;
    font-size: 11px;
}

.magnet_img-container  {
    width: 11.112cm;
    height: 11.112cm;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.magnet_img-container-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(1px);
}

.magnet_img-container-cover {
    position: absolute;
    width: 10.9cm;
    height: 10.9cm;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
}

