@import "../../App.scss";

.search-push-notifications_main {
    height: 91vh;
    width: 100vw;
    background-color: $background-gray;
    overflow-y: scroll;
}

.search-push-notifications_title  {
    height: 10%;
    width: 100%;
}

.search-push-notifications_box {
    height: 100px;
    width: 50%;
    max-width: 600px;
    min-width: 400px;
}


.search-push-notifications_main {
    height: 91vh;
    width: 100vw;
    background-color: $background-gray;
    overflow-y: scroll;
}

.search-push-notifications_input-container-error {
    position: relative;
    margin-left: 30px; 
    margin-right: 20px;
    width: 85%;
    height: 40px;
}

.search-push-notifications_error {
    position: absolute;
    top: 40px;
    // background-color: lightblue;
}


.search-push-notifications_table {
    // border: rgba(0,0,0,0.1) 1px solid;
    width: 90%;
    border-radius: 10px;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    background-color: $lightgray;
}

.search-push-notifications_table-head {
    // background-color: $lightgray;
    // background-color: lightblue;
    // border-radius: 10px;
    height: 100px;

}

.search-push-notifications_table-head-item {
    width: 12.5%;
    text-align: center;
    font-size: 18px;
    font-family: 'GothamBold';
    color: $darkfont;
}

.search-push-notifications_table-item {
    background-color: white;
    height: 150px;
    width: 12.5%;
    border: $lightgray 1.5px solid;
    text-align: center;
    font-size: 15px;
    font-family: 'GothamMedium';
    color: $darkfont;
}

.search-push-notifications_see-more {
    background-color: rgba(0,0,0,0.0);
    border: hidden;
    color: $blue;
    font-size: 18px;
}

.search-push-notifications_count-results {
    width: 90%; 
    height: 30px;
}

.push-notifications-action-button {
    border: 1px solid #51B7F3;
    background-color: white;
    padding: 5px 0px;
    width: 45%;
    font-family: 'GothamMedium';
    font-size: 12px;
    color: #51B7F3;
    border-radius: 5px;
}