
.card_album-left {
    width: 5%;
    height: 100%;
    box-shadow: 1px 0 5px -2px #888;
}

.card_album-right {
    width: 95%;
    height: 100%;
}

.card_img {
    object-fit: contain;
}

.card_img-magnet {
    object-fit: cover;
    border-radius: 10px;
}


.card_border {
    border-bottom: 1.5px solid rgba(0,0,0,0.2);
    width: 100%;
}


.card_container {
    width: 48.5%;
    min-width: 270px;
    height: 450px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.card_top {
    height: 25%;
    width: 100%;
}

.card_center {
    height: 55%;
    width: 100%;
}

.card_bottom {
    height: 20%;
    width: 100%;
}

.card_img-container {
    background-color: white;
    width: 100%;
}

.card_album {
    height: 150px;
    width: 153px;
}

.card_magnet {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.card_magnet img {
    border-radius: 10px;
    object-fit: contain;

}

.card_ornament {
    height: 150px;
    width: 150px;
    border-radius: 75px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.card_ornament img {
    border-radius: 75px;
    object-fit: cover;

}

.card_giftcard-background {
    background: url('../../images/giftcard-frame.png') center no-repeat;
    background-size: contain;
    height: 150px;
}

.card_picture-frame {
    height: 240px;
    width: 240px;
}

.card_picture-img {
    height: 140px;
    width: 140px;
    padding-bottom: 2px;
    padding-right: 2px;
    object-fit: contain;
}

.card_giftcard-img {
    height: 80px;
    object-fit: contain;
    margin-bottom: 25px;
}

.card_item-img {
    height: 140px;
    width: 140px;
    object-fit: cover;
}


.card_data-right {
    width: 40%;
}

.card_button-v1 {
    border: 1px solid #51B7F3;
    background-color: white;
    padding: 5px 0px;
    width: 55%;
    font-family: 'GothamMedium';
    font-size: 12px;
    color: #51B7F3;
    border-radius: 5px;
}

.card_button-v2 {
    border: 1px solid #51B7F3;
    background-color: white;
    padding: 5px 0px;
    width: 35%;
    font-family: 'GothamMedium';
    font-size: 12px;
    color: #51B7F3;
    border-radius: 5px;
} 

.card_button-v3 {
    border: 1px solid #51B7F3;
    background-color: white;
    padding: 5px 0px;
    width: 45%;
    font-family: 'GothamMedium';
    font-size: 12px;
    color: #51B7F3;
    border-radius: 5px;
} 