.hardcover21_main {
    width: 46.7cm;
    height: 25cm;
    margin: 0;
    font-size: 12px;
    position: relative;
    -webkit-print-color-adjust: exact;
}

.hardcover21_line-one {
    width: 2cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_line-two {
    width: 21.7cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_line-three {
    width: 23cm;
    height: 0.8cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover21_line-three-red {
    width: 23.35cm;
    height: 0.8cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover21_line-four {
    width: 23.7cm;
    height: 0.8cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover21_line-five {
    width: 25cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_line-six {
    width: 44.7cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_line-seven {
    width: 0.8cm;
    height: 2cm;
    border-bottom: 1px solid #000;
    position: absolute;
}

.hardcover21_line-eight {
    width: 0.8cm;
    height: 2cm;
    margin-left: 45.9cm;
    border-bottom: 1px solid #000;
    position: absolute;
}

.hardcover21_line-nine {
    width: 0.8cm;
    height: 23cm;
    border-bottom: 1px solid #000;
    position: absolute;
    z-index: 10;
}

.hardcover21_line-ten {
    width: 0.8cm;
    height: 23cm;
    margin-left: 45.9cm;
    border-bottom: 1px solid #000;
    position: absolute;
}

.hardcover21_line-eleven {
    width: 2cm;
    height: 0.8cm;
    margin-top: 24.2cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_line-twelve {
    width: 21.7cm;
    height: 0.8cm;
    margin-top: 24.2cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_line-thirteen {
    width: 23cm;
    height: 0.8cm;
    margin-top: 24.2cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover21_line-thirteen-red {
    width: 23.35cm;
    height: 0.8cm;
    margin-top: 24.2cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover21_line-fourteen {
    width: 23.7cm;
    height: 0.8cm;
    margin-top: 24.2cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover21_line-fifteen {
    width: 25cm;
    height: 0.8cm;
    margin-top: 24.2cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_line-sixteen {
    width: 44.7cm;
    height: 0.8cm;
    margin-top: 24.2cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover21_back {
    width: 23cm;
    height: 25cm;
    float: left;
    position:relative;
}

.hardcover21_pixylogo-back {
    width: 2.7cm;
    height: 1cm;
    position: absolute;
    bottom: 2.4cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100px;
}

.hardcover21_codebar-back {
    width: 2.7cm;
    height: 0.3cm;
    position: absolute;
    bottom: 2.5cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100px;
}

.hardcover21_order-number {
    width: 100%;
    position:absolute;
    bottom: 1.6cm;
    height: 0.95cm;
    padding-top: 0.1cm;
    background: white;
}

.text-center {
    text-align: center;
    color: #c3c3c3;
}

.hardcover21_spine {
    width: 0.7cm;
    height: 25cm;
    float:left;
}

.hardcover21_inner-spine-logo {
    flex-grow: 2;
    justify-content: center;
    display: flex;
}

.hardcover21_logo-spine {
    width: 52%;
    align-self: flex-end;
}

.hardcover21_inner-spine-name {
    flex-grow: 13;
}

.hardcover21_album-name {
    transform-origin: 0 100%;
    position: absolute;
    -webkit-transform: rotate(90deg);
    line-height: 28px;
    width: 450px;
    margin-top: -20px;
    font-weight: bold;
    font-size: 10px;
}

.hardcover21_front {
    width: 23cm;
    height: 25cm;
    float:left;
}

.hardcover21_cover-img {
    width: 18cm;
    height: 18cm;
    margin-left: 1.5cm;
    margin-top: 3.5cm;
    object-fit: cover;
}