.header {
    background: #f2f2f2;
}


.border{
    border: 0.7px solid rgba(0,0,0,0.08);
    width: 85%;
    height: 0.7px;
}

.navbar_container {
    height: 10vh;
    box-shadow: 0 3px 2px rgba(0,0,0,0.05);
    z-index: 2;
}

.navbar_top {
    height: 10vh;
    margin: 0px 70px 0px 40px;
}

.navbar_logo-container {
    width: 20%;
}

.icons_container {
    width: 80%;
}

.navbar_icon {
    color: rgba(0,0,0,0.4);
    text-decoration: inherit;
}

.navbar_dorpdown-icon {
    position: relative;
    color: #00000066;
    text-decoration: inherit;
    min-width: 90px;
}


.navbar_icon-subtext {
    font-size: 14px;
    color: #6E7781;
    font-family: 'GothamMedium';
}

.navbar_icon-subtext-selected {
    font-size: 14px;
    color: #51b7f3;
    font-family: 'GothamMedium';
}

.navbar_dropdown {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 1;
}

.navbar_dropdown-box {
    width: 100%;
    box-shadow: -2px -2px 10px rgba(0,0,0,0.05);
    padding-top: 1px;
}