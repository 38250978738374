@import "../../App.scss";

////////////////////////
.create-banner_main {
    width: 100%;
    height: 100%;
}

.create-banner_inside {
    position: relative;
    width: 95%;
    height: 95%;
    background-color: white;
}

.create_cover_close {
    position: absolute;
    right: 3%;
    top: 3%;
}

.create-banner-form {
    width: 100%;
    //border: rgba(0,0,0,0.3) solid 1px;
}

.create-banner-input-container {
    position: relative;
}

.create-banner-input-label {
    font-size: 15px;
    color: black;
    font-family: 'GothamMedium';
}

.create-banner-input-container input:not([type='checkbox']) {
    width: 50%;
    color: #626262;
    font-size: 15px;
    padding-left: 5px;
}

.create_banner_button {
    color: #51B7F3;
    background-color: white;
    width: 250px;
    height: 50px;
    border: #51B7F3 solid 2px;
    border-radius: 5px;
    font-family: 'GothamMedium';
    font-size: 15px;
}