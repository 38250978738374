@import "../../App.scss";

.cover-categories_main {
    background-color: rgba(0,0,0,0.03);
}

.cover-categories_header {
    background-color: #ffffff;
    height: 5vh;
}

.cover-categories_back-btn {
    width: 15%;
    padding-left: 40px;
}

.border {
    border-bottom: 1px solid rgba(0,0,0,0.08);
    width: 100%;
}

.collections_customer-products {
    width: 100%;
}

.collections_customer-products-inside {
    width: 95%;
}

.collections_container {
    background-color: rgba(0,0,0,0.03);
    height: auto;
    width: 100%;
    padding: 50px 0px;
    min-width: 100vw;
    min-height: 85vh;
}