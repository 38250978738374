
.predesignsoft16_main {
    width: 33.40cm;
    height: 16.50cm;
    margin: 0;
    font-size: 12px;
    position: absolute;
    -webkit-print-color-adjust: exact;
}

.predesignsoft16_back {
    width: 16.45cm;
    height: 16.5cm;
    float: left;
    position: relative;
}

.predesignsoft16_pixylogo-back {
    position: absolute;
    bottom: 1.2cm;
    left: 0; 
    right: 0; 
    margin-left: auto;
    margin-right: auto;
    width: 2.7cm;
    height: 1.7cm;
    background-color: white;
    border-radius: 4px;
} 

.predesignsoft16_codebar-back {
    width: 2.7cm;
    height: 0.4cm;
    background-color: white;
    position: absolute;
    bottom: 1.9cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}

.predesignsoft16_barcode-img {
    width: 100%;
    height: 0.4cm;
    object-fit: cover;
}

.predesignsoft16_order-number {
    width: 100%;
    position:absolute;
    bottom: 1.3cm;
    height: 6mm;
    padding-top: 0.1cm;
    margin-left: auto; 
    margin-right: auto; 
}


.predesignsoft16_spine {
    width: 0.5cm;
    height: 16.5cm;
    float:left;
}

.predesignsoft16_front {
    width: 16.45cm;
    height: 16.5cm;
    float:left;
}