
.softcoveralbum16_main {
    width: 23.998cm;
    -webkit-print-color-adjust: exact;
}

.softcoveralbum16_page {
    position: relative;
    width: 23.998cm;
    height: 23.998cm; 
}

.softcoveralbum16_ordernum {
    position: absolute;
    left: .9584cm;
    top: 21.6cm;
    -webkit-transform: rotate(-90deg);
    color: #c3c3c3;
    font-size: 16px;
}

.softcoveralbum16_indicators-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 23.998cm;
    height: 1.25cm;
}

.softcoveralbum16_header, .softcoveralbum16_footer {
    height: 2.625cm;
    width: 18.748cm;
    overflow: hidden;
}

.softcoveralbum16_indicator {
    height: 1.15cm;
    width: 3px;
}

.softcoveralbum16_date {
    color: #a0a0a0;
    padding-top: 1.34cm;
    font-size: 16px;
}

.softcoveralbum16_comment {
    padding-top: .599cm;
    text-align: center;
    font-size: 16px;
}