$darkgray: #525353;
$lightgray: #F3F3F3;
$blue: #51B7F3;
$darkfont: #6E7781;
$red: #E90B0B;
$background-gray: rgba(0,0,0,0.02);


@font-face {
  font-family: 'GothamMedium';
  src: url(./fonts/GothamMedium.ttf);
}

@font-face {
  font-family: 'GothamBold';
  src: url(./fonts/GothamBold.ttf);
}

@font-face {
  font-family: 'GothamBook';
  src: url(./fonts/gotham-book.otf);
}

.generic-button {
  width: 200px;
  height: 40px;
  border: hidden;
  border-radius: 4px;
  background-color: $blue;
  font-family: 'GothamMedium';
  font-size: 15px;
  color: white;
}


.cursor-pointer {
  cursor: pointer;
}

p {
  margin-bottom: 0px;
}

.gotham-bold-darkgray-25 {
  font-family: 'GothamBold';
  font-size: 25px;
  color: $darkgray;
}

.gotham-bold-darkfont-20 {
  font-family: 'GothamBold';
  font-size: 20px;
  color: $darkfont;
}

.gotham-md-red-12 {
  font-family: 'GothamMedium';
  font-size: 12px;
  color: $red;
}

.gotham-bold-darkfont-15 {
  font-size: 15px;
  font-family: 'GothamBold';
  color: $darkfont;
  
}

.gotham-book-12 {
  font-family: 'GothamBook';
  font-size: 12px;
  color: #525353;
}

.gotham-md-darkfont-15 {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: $darkfont
} 

.gotham-bold-blue-18 {
  font-family: 'GothamBold';
  font-size: 18px;
  color: $blue;
}

.gotham-book-darkfont-15 {
  font-family: 'GothamBook';
  font-size: 15px;
  color: $darkfont;
}

//// 

.gotham_bold-white-24 {
  font-family: 'GothamBold';
  font-size: 24px;
  color: white;
}

.gotham_bold-24 {
  font-family: 'GothamBold';
  font-size: 24px;
  color: #6E7781;
}

.gotham_bold-10 {
  font-family: 'GothamBold';
  font-size: 10px;
  color: #6E7781;
}

.gotham_bold-12 {
  font-family: 'GothamBold';
  font-size: 12px;
  color: #6E7781;
}

.gotham_bold-14 {
  font-family: 'GothamBold';
  font-size: 14px;
  color: #6E7781;
}

.gotham_bold-16 {
  font-family: 'GothamBold';
  font-size: 16px;
  color: #6E7781;
}

.gotham_bold-18 {
  font-family: 'GothamBold';
  font-size: 18px;
  color: #6E7781;
}

.gotham_book-white-24 {
  font-family: 'GothamBook';
  font-size: 24px;
  color: white;
}

.gotham_bold-16 {
  font-family: 'GothamBold';
  font-size: 16px;
  color: #6E7781;
  // background-color: blue;
}

.gotham_bold-blue-14 {
  font-family: 'GothamBold';
  font-size: 14px;
  color: #51B7F3;
}

.gotham_book-16 {
  font-family: 'GothamBook';
  font-size: 16px;
  color: #6E7781;
  // background-color: lightblue;
}

.gotham_book-14 {
  font-family: 'GothamBook';
  font-size: 14px;
  color: #525353;
}

.gotham_book-12 {
  font-family: 'GothamBook';
  font-size: 12px;
  color: #525353;
}

.gotham_medium-blue-12 {
  font-family: 'GothamMedium';
  font-size: 12px;
  color: #51B7F3;
}

.gotham-md-16 {
  font-family: 'GothamMedium';
  font-size: 16px;
  color: #6E7781;
}

.gotham-book-darkfont-16 {
  font-size: 16px;
  font-family: 'GothamBook';
  color: $darkfont;
}

.gotham-bold-darkfont-16 {
  font-size: 16px;
  font-family: 'GothamBold';
  color: $darkfont;
}

