.comment-item_main {
    position: relative;
    border-top: 1px solid rgba(0,0,0,0.05);
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.comment-item_text {
    text-align: left;
    padding-right: 30px;
}

