.loading_main {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

.loading_local {
  width: 100%;
  height: 30px;
  margin-top: 50px;
  margin-bottom: 100px;
}