.hardcover_main {
    font-family: Helvetica, sans-serif;
    width: 20.75cm;
    margin: 0;
    font-size: 12px;
    -webkit-print-color-adjust: exact;
}

.hardcover_indicators-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 26.25cm;
    height: 30px;
}

.hardcover_page {
    position: relative;
    width: 26.25cm;
    height: 26.6433cm;
    // border: lightgray 1px solid;
}

.hardcover_ordernum {
    position: absolute;
    left: 32px;
    top: 24cm;
    -webkit-transform: rotate(-90deg);
    color: #c3c3c3;
    font-size: 13px;
}

.hardcover_header, .hardcover_footer {
    height: 3.83cm;
    width: 18.99cm;
    overflow: hidden;
}

.hardcover_date {
    padding-top: 2.8cm;
    color: #a0a0a0;
    font-size: 15px;
}

.hardcover_comment {
    padding-top: .6cm;
    text-align: center;
    font-size: 15px;
}