.hardcover16_main {
    width: 35.9cm;
    height: 19cm;
    margin: 0;
    font-size: 12px;
    // border: lightgray 1px solid;
    position: absolute;;
    -webkit-print-color-adjust: exact;
}

.hardcover16_line-one {
    width: 1.5cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover16_line-two {
    width: 16.2cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover16_line-three {
    width: 17.5cm;
    height: 0.8cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover16_line-three-red {
    width: 17.85cm;
    height: 0.8cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover16_line-four {
    width: 18.2cm;
    height: 0.8cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover16_line-five {
    width: 19.5cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover16_line-six {
    width: 34.2cm;
    height: 0.8cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover16_line-seven {
    width: 0.8cm;
    height: 1.5cm;
    border-bottom: 1px solid #000;
    position: absolute;
}

.hardcover16_line-eight {
    width: 0.8cm;
    height: 1.5cm;
    margin-left: 35.1cm;
    border-bottom: 1px solid #000;
    position: absolute;
}

.hardcover16_line-nine {
    width: 0.8cm;
    height: 17.5cm;
    border-bottom: 1px solid #000;
    position: absolute;
}

.hardcover16_line-ten {
    width: 0.8cm;
    height: 17.5cm;
    margin-left: 35.1cm;
    border-bottom: 1px solid #000;
    position: absolute;
}

.hardcover16_line-eleven {
    width: 1.5cm;
    height: 0.8cm;
    margin-top: 18.2cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover16_line-twelve {
    width: 16.2cm;
    height: 0.8cm;
    margin-top: 18.2cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover16_line-thirteen {
    width: 17.5cm;
    height: 0.8cm;
    margin-top: 18.2cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover16_line-thirteen-red {
    width: 17.85cm;
    height: 0.8cm;
    margin-top: 18.2cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover16_line-fourteen {
    width: 18.2cm;
    height: 0.8cm;
    margin-top: 18.2cm;
    border-right: 1px solid red;
    position: absolute;
}

.hardcover16_line-fifteen {
    width: 19.5cm;
    height: 0.8cm;
    margin-top: 18.2cm;
    border-right: 1px solid #000;
    position: absolute;
}

.hardcover16_line-sixteen {
    width: 34.2cm;
    height: 0.8cm;
    margin-top: 18.2cm;
    border-right: 1px solid #000;
    position: absolute;
}


///
.hardcover16_back {
    width: 17.5cm;
    height: 19cm;
    float: left;
    position:relative;
}

.hardcover16_pixylogo-back {
    width: 2.7cm;
    height: 1cm;
    position: absolute;
    bottom: 2.4cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100px;
}

.hardcover16_codebar-back {
    width: 2.7cm;
    height: 0.3cm;
    position: absolute;
    bottom: 2.5cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100px;
}

.hardcover16_order-number {
    width: 100%;
    position:absolute;
    bottom: 1.6cm;
    height: 0.8cm;
    padding-top: 0.1cm;
    background: white;
}

.text-center {
    text-align: center;
    color: #c3c3c3;
}

.hardcover16_spine {
    width: 0.7cm;
    height: 19cm;
    float:left;
}

.hardcover16_inner-spine-logo {
    flex-grow: 2;
    justify-content: center;
    display: flex;
}

.hardcover16_logo-spine {
    width: 52%;
    align-self: flex-end;
}

.hardcover16_inner-spine-name {
    flex-grow: 13;
}

.hardcover16_album-name {
    transform-origin: 0 100%;
    position: absolute;
    -webkit-transform: rotate(90deg);
    line-height: 28px;
    width: 450px;
    margin-top: -20px;
    font-weight: bold;
    font-size: 10px;
}

.hardcover16_front {
    width: 17.5cm;
    height: 19cm;
    float:left;
}

.hardcover16_cover-img {
    width: 13.7cm;
    height: 13.7cm;
    margin-left: 1.8cm;
    margin-top: 2.65cm;
    object-fit: cover;
}