@import "../../App.scss";

.card-cover_border {
    border-bottom: 1.5px solid rgba(0,0,0,0.2);
    width: 100%;
}


.card-category_container {
    width: 90%;
    min-width: 335px;
    height: 450px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.card-cover_container {
    width: 90%;
    min-width: 335px;
    height: 330px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.card-banner_container {
    width: 90%;
    min-width: 335px;
    height: 450px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.card-cover_top {
    height: 25%;
    width: 100%;
}

.card-cover_bottom {
    height: 15%;
    width: 100%;
}

.card-cover_center {
    height: 55%;
    width: 100%;
}

.card-banner_center {
    height: 45%;
    width: 100%;
}

.card-banner {
    max-height: 210px;
}

.card-cover {
    height: 150px;
    width: 90%;
}

.card-cover_data-right {
    width: 40%;
}

.card-cover_button {
    border: 1px solid #51B7F3;
    background-color: white;
    padding: 5px 0px;
    width: 45%;
    font-family: 'GothamMedium';
    font-size: 12px;
    color: #51B7F3;
    border-radius: 5px;
} 

.card_main-container {
    width: 25%;
    height: calc(100vw * 16/50);
}

.card-cover_main-container {
    width: 33%;
    height: calc(65vw * 16/50);
}

.card-banner_main-container {
    width: 33%;
    height: calc(90vw * 16/50);
}

.card-quote_main-container {
    width: 25%;
    height: calc(65vw * 16/50);
}

.card-cover_album {
    width: 80%;
    height: calc(100vw * 15/100);
}