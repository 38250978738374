@import "../../App.scss";

.create_cover-wrapper {
    width: 40rem;
}

////////////////////////
.create_cover_main {
    width: 100%;
    height: 100%;
}

.create_cover_inside {
    position: relative;
    width: 650px;
    height: 750px;
    background-color: white;
}

.create_cover_close {
    position: absolute;
    right: 3%;
    top: 3%;
}

.create-cover-form {
    width: 100%;
    //border: rgba(0,0,0,0.3) solid 1px;
}

.create-cover-input-container {
    position: relative;
}

.create-cover-input-label {
    font-size: 15px;
    color: black;
    font-family: 'GothamMedium';
}

.create-cover-input-container input:not([type='checkbox']) {
    width: 50%;
    color: #626262;
    font-size: 15px;
    padding-left: 5px;
}

.create_cover_button {
    color: #51B7F3;
    background-color: white;
    width: 250px;
    height: 50px;
    border: #51B7F3 solid 2px;
    border-radius: 5px;
    font-family: 'GothamMedium';
    font-size: 15px;
}