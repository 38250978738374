@import "../../App.scss";

.order-detail_main {
    background-color: rgba(0,0,0,0.03);
}

.order-detail_header {
    background-color: #51B7F3;
    height: 15vh;
}

.order-detail_back-btn {
    width: 15%;
    padding-left: 40px;
}

.order-detail_header-bottom {
    padding-left: 35px;
}


.order-detail_content {
    width: 100%;
}

.order-detail_status {
    width: 15%;
    background-color: #FFFFFF;
    height: 100vh;
}

.order-detail_center {
    width: 60%;
    overflow-y: scroll;
    height: 100vh;
}

.order-detail_comments {
    width: 25%;
    background-color: #FFFFFF;
    height: 100vh;
    box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.25);
}

.order-detail_section-detail {
    width: 70%;
    max-width: 700px;
    background-color: white;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.03);
}

.order-detail_products-detail {
    width: 70%;
    max-width: 700px;
    // background-color: white;
    // box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.03);
}

.order-detail_item {
    border-top: rgba(0,0,0,0.1) 2px solid;
}

.order-detail_section-title {
    width: 70%;
    max-width: 700px;
}

.order-detail_img {
    width: 10%;
    // background-color: salmon;
}

.order-detail_item-content {
    width: 100%;
    // background-color: blue;
}

.order-detail_buttons {
    width: 70%;
    max-width: 700px;
}


.border{
    border-bottom: 1px solid rgba(0,0,0,0.08);
    width: 80%;
}

.order-detail_btn {
    width: 49%;
    height: 50px;
    background-color: rgba(0,0,0,0.0);
    margin-bottom: 5px;
    border: 1px solid #51B7F3;
    font-family: 'GothamMedium';
    font-size: 15px;
    color: #51B7F3;
}


