.softcover16_main {
    margin: 0;
    font-size: 12px;
    width: 33.4cm; 
    height: 16.4cm;
    -webkit-print-color-adjust: exact;
    // border: lightgray 1px solid;
}


.softcover16_back {
    width: 165mm;
    height: 164mm;
    float: left;
    position:relative;
}

.softcover16_spine {
    width: 4mm;
    height: 164mm;
    float:left;
}



.softcover16_front {
    width: 165mm;
    height: 164mm;
    float:left;
}


.softcover16_cover-img {
    width: 140mm;
    height: 140mm;
    margin: 12mm 15mm 12mm 10mm;
    object-fit: cover;
}


.softcover16_text-center {
    text-align: center;
    vertical-align: bottom;
    color: #c3c3c3;
}

.softcover16_inner-spine-logo {
    flex-grow: 1;
    justify-content: center;
    display: flex;
}

.softcover16_logo-spine {
    width: 70%;
    align-self: flex-end;
}

.softcover16_inner-spine-name {
    flex-grow: 14;
}

.softcover16_album-name {
    transform-origin: 0 100%;
    position: absolute;
    -webkit-transform: rotate(90deg);
    line-height: 19px;
    width: 450px;
    height: 4mm;
    margin-top: -10px;
    font-weight: bold;
    font-size: 10px;
}

.softcover16_barcode-data {
    position: absolute; 
    bottom: 6.9mm;
    left: 67.5mm; 
    width: 30mm;
    height: 15mm;
}