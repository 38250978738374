@import "../../App.scss";

.survey-detail_main {
    background-color: rgba(0,0,0,0.03);
}

.survey-detail_header {
    background-color: #51B7F3;
    height: auto;
}

.survey-detail_content {
    width: 100%;
}

.survey-detail_status {
    width: 15%;
    background-color: #FFFFFF;
    height: 100vh;
}

.survey-detail_center {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.survey-detail_section-detail {
    width: 70%;
    background-color: white;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.03);
    
}

.survey-detail_section-title {
    width: 70%;
}


