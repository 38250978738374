

.noframe_img-container {
    height: 28.126cm;
    width: 26.3cm;
    page-break-after: always;
}

.noframe_img {
    height: 26.3cm; 
    width: 26.3cm; 
    object-fit: cover;
}

