@import "../../App.scss";

.search-coupons_main {
    height: 91vh;
    width: 100vw;
    background-color: $background-gray;
    overflow-y: scroll;
}

.search-coupons_form  {
    height: 50%;
    width: 100%;
}

.search-coupons_box {
    height: 100px;
    width: 50%;
    max-width: 600px;
    min-width: 400px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
}


.search-coupons_main {
    height: 91vh;
    width: 100vw;
    background-color: $background-gray;
    overflow-y: scroll;
}

.search-coupons_form  {
    height: 50%;
    width: 100%;
}

.search-coupons_box {
    height: 100px;
    width: 50%;
    max-width: 600px;
    min-width: 400px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
}

.search-coupons_filters-box {
    height: 100px;
    width: 50%;
    max-width: 600px;
    min-width: 400px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.1);
}



.search-coupons_button {
    height: 100%;
    width: 15%;
    border: hidden;
    border-radius: 0px 30px 30px 0px;
    background-color: $blue;
}

.search-coupons_input-container {
    position: relative;
    margin-left: 30px; 
    margin-right: 20px;
    border-bottom: black 1px solid; 
    width: 85%;
    height: 40px;
}

.search-coupons_input-container-error {
    position: relative;
    margin-left: 30px; 
    margin-right: 20px;
    border-bottom: $red 1px solid; 
    width: 85%;
    height: 40px;
}

.search-coupons_input {
    width: 100%;
    height: 100%;
    border: hidden;
    font-family: 'GothamBook';
    color: black;
    font-size: 20px;
}

.search-coupons_error {
    position: absolute;
    top: 40px;
    // background-color: lightblue;
}


.search-coupons_table {
    // border: rgba(0,0,0,0.1) 1px solid;
    width: 90%;
    border-radius: 10px;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    background-color: $lightgray;
}

.search-coupons_table-head {
    // background-color: $lightgray;
    // background-color: lightblue;
    // border-radius: 10px;
    height: 100px;

}

.search-coupons_table-head-item {
    width: 12.5%;
    text-align: center;
    font-size: 18px;
    font-family: 'GothamBold';
    color: $darkfont;
}

.search-coupons_table-item {
    background-color: white;
    height: 150px;
    width: 12.5%;
    border: $lightgray 1.5px solid;
    text-align: center;
    font-size: 15px;
    font-family: 'GothamMedium';
    color: $darkfont;
}

.search-coupons_see-more {
    background-color: rgba(0,0,0,0.0);
    border: hidden;
    color: $blue;
    font-size: 18px;
}

.search-coupons_count-results {
    width: 90%; 
    height: 30px;
}