.edit-name_container {
    width: 100%;
    height: 100%;
}

.edit-name_container-inside {
    position: relative;
    width: 50%;
    max-width: 700px;
    height: 35%;
    min-height: 250px;
    max-height: 350px;
    background-color: white;
    border-radius: 15px;
}

.edit-name_close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.edit-name_count-characters {
    width: 60%;
}

@media(max-width: 1100px) {
    .edit-name_container-inside {
        position: relative;
        width: 80%;
        height: 35%;
        background-color: white;
    }
    
}

.edit-name_buttons-container {
    width: 60%;
}

.edit-name_input-container {
    width: 60%;
    border-bottom: rgba(0,0,0,1) 1px solid;
}

.edit-name_ok-button {
    width: 45%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.edit-name_cancel-button {
    width: 45%;
    height: 40px;
    color: #51B7F3;
    border: #51B7F3 2px solid;
    background-color:  white;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}