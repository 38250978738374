@import "../../App.scss";

.create-address-wrapper {
    width: 40rem;
}

.warning-container {
    background-color: #4eb6b2;
}

////////////////////////
.create-address_main {
    width: 100%;
    height: 100%;
}

.create-address_inside {
    position: relative;
    width: 650px;
    height: 750px;
    // falta min-width y min-height
    background-color: white;
}

.create-address_close {
    position: absolute;
    right: 3%;
    top: 3%;
}

.create-address_form {
    width: 80%;
    // height: 85%;
    //border: rgba(0,0,0,0.3) solid 1px;
}


.crate-address_input-container {
    position: relative;
    height: 40px;
    background-color: lightgray;
}

.crate-address_input-label {
    top: -2px;
    left: 2px;
    position: absolute;
    font-size: 11px;
    color: black;
    font-weight: bold;
    font-family: 'GothamBold';
}

.crate-address_input-container input {
    padding-left: 10px;
    height: 50px;
    width: 100%;
    border: hidden;
    color: #626262;
    font-size: 15px;
}

.crate-address_input-container-right {
    position: relative;
    height: 40px;
    border-left: rgba(0,0,0,0.3) 1px solid;
}

.crate-address_input-container-right input {
    padding-left: 10px;
    height: 50px;
    width: 100%;
    border: hidden;
    color: #626262;
    font-size: 15px;
}


.create-address_button {
    color: #51B7F3;
    background-color: white;
    width: 250px;
    height: 50px;
    border: #51B7F3 solid 2px;
    border-radius: 5px;
    font-family: 'GothamMedium';
    font-size: 15px;
  }