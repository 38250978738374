.comments_main {
    height: 100vh;
}

.comments_top {
    height: 75vh;
    overflow-y: scroll;
}

.comments_bottom {
    height: 25vh;
}

.comments_input {
    height: 65%;
    width: 80%;
    // background-color: lightblue;
    padding-left: 10px;
    padding-top: 10px;
    border: 1px solid rgba(110, 119, 129, 0.51);
    border-radius: 10px;
}

.comments_input textarea {
    height: 90%;
    width: 90%;
    border: none;
    resize: none;
    outline: none;
    color: #6E7781;
}

.comments_submit {
    width: 80%;
}