
.order-status_main {
    height: 100%;
}

.order-status_top {
    height: 7%;
    border-bottom: 0.5px solid rgba(0,0,0,0.1);
}

.order-status_tile {
    height: 7%;
    border-top: 0.5px solid rgba(0,0,0,0.1);
    border-bottom: 0.5px solid rgba(0,0,0,0.1);
}

.order-status_status-items {
    height: 60%;
}

.order-status_action-items {
    height: 26%;
}