.softcover21_main {
    margin: 0;
    font-size: 12px;
    width: 43.1cm; 
    height: 21.65cm;
    -webkit-print-color-adjust: exact;
}


.softcover21_back {
    width: 21.3cm;
    height: 21.6cm;
    float: left;
    position:relative;
}

.softcover21_spine {
    width: 0.5cm;
    height: 21.6cm;
    float:left;
}

.softcover21_front {
    width: 21.3cm;
    height: 21.6cm;
    float:left;
}

.softcover21_cover-img {
    width: 19cm;
    height: 19cm;
    margin-left: 1cm;
    margin-top: 1.3cm;
    object-fit: cover;
}

.softcover21_pixylogo-back {
    width: 2.7cm;
    height: 1cm;
    position: absolute;
    bottom: 0.95cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100px;
}

.softcover21_codebar-back {
    width: 2.7cm;
    height: 0.3cm;
    position: absolute;
    bottom: 1.1cm;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100px;
}

.softcover21_order-number {
    width: 100%;
    position:absolute;
    bottom: 0.2cm;
    height: 0.8cm;
    padding-top: 0.1cm;
    background: white;
}

.softcover21_text-center {
    text-align: center;
    color: #c3c3c3;
}

.softcover21_inner-spine-logo {
    flex-grow: 1;
    justify-content: center;
    display: flex;
}

.softcover21_logo-spine {
    width: 70%;
    align-self: flex-end;
}

.softcover21_inner-spine-name {
    flex-grow: 14;
}

.softcover21_album-name {
    transform-origin: 0 100%;
    position: absolute;
    -webkit-transform: rotate(90deg);
    line-height: 19px;
    width: 450px;
    margin-top: -10px;
    font-weight: bold;
    font-size: 10px;
}